<template>
  <tr>
    <td class="text-center">
      <v-btn
        color="primary"
        small
        class="btn-edit"
        @click="openPriceEditDialog(product)"
        :class="{ sm: !isLarge }"
      >
        <v-icon dense> mdi-pencil</v-icon>
      </v-btn>
    </td>
    <td class="text-center x-sm">
      <img
        v-if="!product.path_img_thumb && !product.path_img"
        :src="require('@/assets/images/supply-item.png')"
        alt=""
        class="image-placeholder"
        :class="{ sm: !isLarge }"
      />
      <v-img
        v-if="product.path_img && !product.path_img_thumb"
        :src="product.path_img"
        class="image"
        :class="{ sm: !isLarge }"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              :size="isLarge ? '20' : '10'"
              :width="isLarge ? '2' : '1'"
              color="green lighten-1"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-img
        v-if="product.path_img_thumb && product.path_img"
        :src="product.path_img_thumb"
        class="image"
        :class="{ sm: !isLarge }"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              :size="isLarge ? '20' : '10'"
              :width="isLarge ? '2' : '1'"
              color="green lighten-1"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </td>
    <td class="text-center">{{ product.name }}</td>
    <td class="text-center">{{ product.price_init }}</td>
    <td class="text-center">{{ product.price_sell }}</td>
    <td class="text-center">{{ product.price_min }}</td>
    <td class="text-center">{{ product.price_max }}</td>
  </tr>
</template>

<script>
import SizeUi from "@/mixins/SizeUi";
import EventBus from "@/events/EventBus";

export default {
  mixins: [SizeUi],
  props: ["product"],
  methods: {
    openPriceEditDialog(e) {
      EventBus.$emit("openPriceEditDialog", e);
    },
  }
};
</script>

<style lang="scss" scoped>
.price-input {
  width: 100%;
  font-size: 12px;
  cursor: pointer;
  min-width: 100px;
}
</style>
