<template>
  <div class="mb-2">
    <v-card class="my-2">
      <v-card-title class="py-0 pl-1">
        <v-row
          :class="[
            'align-center',
            'custom-v-row',
            $vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : ''
            ]"
        >
          <v-col class="custom-v-col">
            <v-col cols="auto">
              {{ $t("menu.prices") }}
            </v-col>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <prices-list-table />
    <pricesDialog
      :visible="visibleDialog"
      @close="visibleDialog = false"
      :product="product"
      v-if="visibleDialog"
    />
  </div>
</template>

<script>
import loader from "@/mixins/loader";
import language from "@/mixins/language";
import user from "../../mixins/user";
import SizeUi from "../../mixins/SizeUi";
import pricesRow from "./pricesRow.vue";
import EventBus from "@/events/EventBus";
import pricesListTable from "@/components/prices/pricesListTable.vue";
import pricesMixin from "@/components/prices/pricesMixin";
import pricesDialog from "@/components/prices/pricesDialog.vue";
import {mapActions, mapGetters} from "vuex";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";

export default {
  components: {
    pricesDialog,
    pricesListTable,
    pricesRow,
  },
  mixins: [loader, language, user, SizeUi, pricesMixin],
  data: () => ({
    product: "",
    visibleDialog: false,
    loadTime: 0
  }),
  async mounted() {
    EventBus.$on("openPriceEditDialog", async (e) => {
      this.openPriceEditDialog(e);
    });
    EventBus.$on("on-close-dialog", async (e) => {
      this.visibleDialog = false;
    });
    this.checkLoadTime();
  },
  methods: {
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    openPriceEditDialog(e) {
      this.product = e;
      this.visibleDialog = true;
    },
  },
  computed: {
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
  }
};
</script>

<style scoped lang="scss">
.custom-v-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.custom-v-col {
  display: flex;
  align-items: center;
}
</style>
