<template>
  <VRow no-gutters align="center" justify="space-between">
    <VCol cols="12" :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''">
      <VCard class="mb-1 pt-2 pb-1">
        <ns-data-table
          :headers="pricesHeader"
          class="my-table supply"
          dense
          :request-service="getRequestService"
          :search-params="params"
        >
          <template v-slot:[`header.btn`]>
            <v-icon dense> mdi-cogs</v-icon>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.btn="{ item }">
            <VBtn
              color="primary"
              small
              class="btn-edit"
              @click="openPriceEditDialog(item)"
              :class="{ sm: !isLarge }"
            >
              <v-icon dense> mdi-pencil</v-icon>
            </VBtn>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.image="{ item }">
            <img
              v-if="!item.path_img_thumb && !item.path_img"
              :src="require('@/assets/images/supply-item.png')"
              alt=""
              class="image-placeholder"
              :class="{ sm: !isLarge }"
            />
            <v-img
              v-if="item.path_img && !item.path_img_thumb"
              :src="item.path_img"
              class="image"
              :class="{ sm: !isLarge }"
            >
              <template v-slot:placeholder>
                <VRow class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    :size="isLarge ? '20' : '10'"
                    :width="isLarge ? '2' : '1'"
                    color="green lighten-1"
                  ></v-progress-circular>
                </VRow>
              </template>
            </v-img>
            <v-img
              v-if="item.path_img_thumb && item.path_img"
              :src="item.path_img_thumb"
              class="image"
              :class="{ sm: !isLarge }"
            >
              <template v-slot:placeholder>
                <VRow class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    :size="isLarge ? '20' : '10'"
                    :width="isLarge ? '2' : '1'"
                    color="green lighten-1"
                  ></v-progress-circular>
                </VRow>
              </template>
            </v-img>
          </template>
        </ns-data-table>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>

import pricesRow from "@/components/prices/pricesRow.vue";
import SizeUi from "../../mixins/SizeUi";
import EventBus from "@/events/EventBus";
import priceService from "@/services/request/prices/priceService";

export default {
  components: { pricesRow },
  data: () => ({
    params: {}
  }),
  mixins: [SizeUi],
  methods: {
    openPriceEditDialog(e) {
      EventBus.$emit("openPriceEditDialog", e);
    },
  },
  computed: {
    getRequestService(){
      return priceService.getPrices
    },
    pricesHeader() {
      return [
        { text: '#', value: 'order', sortable: false },
        {
          text: "",
          value: "btn",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("table.photo"),
          value: "image",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("form.title"),
          value: "name",
          align: "center",
          sortable: false
        },
        {
          text: `${this.$t("table.init_price")} (${this.$t("paymentPlan.uah")})`,
          value: "price_init",
          align: "center",
          sortable: false
        },
        {
          text: `${this.$t("table.sell_price")} (${this.$t("paymentPlan.uah")})`,
          value: "price_sell",
          align: "center",
          sortable: false
        },
        {
          text: `${this.$t("table.min_price")} (${this.$t("paymentPlan.uah")})`,
          value: "price_min",
          align: "center",
          sortable: false
        },
        {
          text: `${this.$t("table.max_price")} (${this.$t("paymentPlan.uah")})`,
          value: "price_max",
          align: "center",
          sortable: false
        }
      ];
    }
  }
};
</script>
