<template>
  <PricesComponent />
</template>

<script>
import PricesComponent from "@/components/prices/PricesComponent.vue";

export default {
  name: "Prices",
  components: {
    PricesComponent,
  },
};
</script>
